import { propertyTypes } from 'App/pages/ComparePlans/data';
import {
  productsMap,
  PRODUCT,
  PAYMENT_METHOD_TYPE_ACCOUNT,
  PAYMENT_METHOD_TYPE_CARD,
  TRANSFER_TYPE,
  LIFE_SUPPORT_TYPE,
} from './constants';
import cookie from 'js-cookie';
import { isEmpty, getAddressLineOneFromAddress } from './helper';
import { unselectHasConcessionCard } from 'App/customConfig';

const defaultSignupObject = {
  applicationId: '',
  currentPage: '',
  unstructuredAddress: '',
  propertyType: '',
  promotionCode: '',
  accountName: null,
  tradingName: null,
  industryCode: null,
  emailAddress: null,
  abnNumber: null,
  acnNumber: null,
  propertyDetail: {
    propertyAddress: null,
    embeddedNetworkCode: null,
    hasSolarPanels: false,
    hasObstacles: null,
    obstacles: null,
    hasRisks: null,
    risks: null,
  },
  hasDifferentPostalAddress: null,
  postalAddress: {},
  transferDetail: null,
  propertyOwner: null,
  hasAdditionalContacts: null,
  contacts: null,
  hasLifeSupportPerson: null,
  lifeSupportPersonDetail: null,
  lifeSupportType: '', // D , E , G
  services: [
    {
      siteIdentifier: '',
      productType: '',
      productOffering: {
        offeringCode: '',
        pricePlanCode: '',
        usageCategory: '',
        paymentFrequency: '',
        estimatedCost: 0,
      },
      hasConnection: null,
    },
  ],
  hasConcessionCard: undefined,
  concessionCardDetail: null,
  hasPreferredMeterReader: null,
  preferredMeterReader: null,
  identifications: null,
  paymentDetails: null,
  correspondenceDelivery: null,
  sameDeliveryMethod: null,
  acceptedContractTerms: null,
  referralSource: null,
  referralAccountNumber: null,
  acceptedMarketingTerms: null,
  acceptedInfoTerms: null,
  acceptedPrivacyTerms: null,
  acceptedCreditReport: null,
  acceptedEICTerms: null,
  acceptedHasInterestOnInterenet: false,
};

// Convert Uconx feed in into Zappy object
const feedinMapping = offer => {
  if (isEmpty(offer.feedin)) {
    return [];
  }

  // Order by lowest to highest FiT
  // @TODO take into account stepped rates, by summing the rates first before sorting
  const sortedFeedin = offer.feedin.sort((a, b) => {
    return a.rates[0].rate > b.rates[0].rate
      ? 1
      : a.rates[0].rate === b.rates[0].rate
      ? 0
      : -1;
  });
  const exclusiveFeedin = sortedFeedin.filter(
    feedin => feedin.is_exclusive === true
  );

  if (!isEmpty(exclusiveFeedin)) {
    return exclusiveFeedin;
  }

  return sortedFeedin;
};

/**
 * Map Uconx pricing query API response into
 * Zappy's state.
 */
export const offerMapping = (data, type, singleOffer = false) => {
  const offers = data.map(obj => {
    const isPower = type === PRODUCT.ELECTRICITY;
    const {
      id,
      description: name,
      features,
      meter_types: rateWrapper,
      terms_html: termsHtml,
      website_terms: websiteTerms,
      greenpower_options: greenpowerOptions,
      site_script: siteScriptHtml,
      terms_and_conditions_id: termsAndConditionsId,
      promocode,
      requires_smart_meter_upgrade: requiresSmartMeterUpgrade,
    } = obj;

    if (!rateWrapper) {
      throw new Error('No meters available.');
    }

    const {
      epfs_url: epfsLink,
      epfs_id: epfsId,
      supply,
      meters,
      // reference_price_statement_price: estimatedElectricityCost,
      reference_price_statement_percentage: estimatedElectricityCostPercentage,
    } = rateWrapper[0];

    // Don't need to collect price for Gas, won't display price on offer tile
    const estimatedCost = rateWrapper[0]?.reference_price_statement_price;
    const referenceCost = rateWrapper[0]?.benchmark_price;
    const referenceUsage = rateWrapper[0]?.benchmark_usage;

    const estimatedCostPercentage = isPower
      ? estimatedElectricityCostPercentage
      : null;
    const description =
      process.env.REACT_APP_BRAND_CODE === 'FIRST'
        ? rateWrapper[0].reference_price_statement
        : isPower
        ? rateWrapper[0].reference_price_statement
        : null;
    const descriptionHtml =
      process.env.REACT_APP_BRAND_CODE === 'FIRST'
        ? rateWrapper[0].reference_price_statement_html
        : isPower
        ? rateWrapper[0].reference_price_statement_html
        : null;

    // normalize the supply charge formatting to match the meter rates
    const rates = [
      ...meters,
      supply.meter !== 0
        ? {
            description: '',
            period: '',
            rates: [
              {
                formattedRange: '',
                range: 'Controlled Load Daily Supply Charge',
                rate: supply.meter,
                unit: 'day',
              },
            ],
          }
        : undefined,
      {
        description: '',
        period: '',
        rates: [
          {
            formattedRange: '',
            range: 'Daily Supply Charge',
            rate: supply.daily,
            unit: 'day',
          },
        ],
      },
    ].filter(rate => rate !== undefined);

    const productOfferingEstimates = estimatedCost
      ? [
          {
            estimatedCost,
            estimatedCostPercentage,
            referenceCost,
            referenceUsage,
            unitOfMeasure: '$',
            usageCategory: 'MONTHLY',
          },
        ]
      : null;

    const productOfferingEstimate = estimatedCost
      ? {
          estimatedCost:
            estimatedCost > 0 ? (estimatedCost / 12).toFixed(2) : estimatedCost,
          estimatedCostPercentage,
          referenceCost:
            referenceCost > 0 ? (referenceCost / 12).toFixed(2) : referenceCost,
          referenceUsage,
          unitOfMeasure: '$',
          period: 'mo',
        }
      : null;

    return {
      id,
      name,
      rates,
      features,
      description,
      descriptionHtml,
      epfsId,
      epfsLink,
      requiresSmartMeterUpgrade,
      offeringCode: obj.id,
      pricePlanCode: obj.id,
      productType: {
        code: type,
        color: null,
        display: isPower ? 'Electricity' : 'Gas',
        displayAlternative: isPower ? 'nmi' : 'mirn',
        icon: isPower ? 'bolt' : 'fire',
        isDefault: false,
      },
      productOfferingEstimate: productOfferingEstimate,
      productOfferingEstimates: productOfferingEstimates,
      termsHtml,
      websiteTerms,
      siteScriptHtml,
      summary: obj.detail,
      feedin: feedinMapping(obj),
      greenpowerOptions,
      termsAndConditionsId,
      promocode,
    };
  });

  return singleOffer ? offers[0] : offers;
};

const electricityMapping = obj => {
  const {
    address: {
      ApartmentNumber: apartmentNumber,
      ApartmentType: apartmentType,
      StreetNumber: streetNumber,
      StreetNumberSuffix: streetNumberSuffix,
      StreetName: streetName,
      StreetSuffix: streetSuffix,
      StreetType: streetType,
      State: state,
      Suburb: suburb,
      PostCode: postCode,
      unstructured_line_1: unstructuredLine1,
      unstructured_line_2: unstructuredLine2,
      unstructured_line_3: unstructuredLine3,
    } = {},
    formattedAddress,
    nmi: util,
  } = obj;
  return {
    apartmentNumber: apartmentNumber || '',
    apartmentType: apartmentType || '',
    streetNumber: streetNumber || '',
    streetNumberSuffix: streetNumberSuffix || '',
    streetName: streetName || '',
    streetType: streetType || '',
    streetTypeSuffix: streetSuffix || '',
    state,
    suburb,
    postCode,
    unstructuredLine1,
    unstructuredLine2,
    unstructuredLine3,
    formattedAddress,
    util,
  };
};

const gasMapping = obj => {
  const {
    address: {
      flatUnitType,
      unitNumber,
      houseNumber1,
      houseNumberSuffix1,
      streetName,
      streetSuffix1,
      streetType,
      state,
      suburb,
      postcode: postCode,
    } = {},
    formattedAddress,
    mirn: util,
  } = obj;
  return {
    apartmentNumber: unitNumber || '',
    apartmentType: flatUnitType || '',
    streetNumber: houseNumber1 || '',
    streetNumberSuffix: houseNumberSuffix1 || '',
    streetName,
    streetType,
    streetTypeSuffix: streetSuffix1 || '',
    state,
    suburb,
    postCode,
    formattedAddress,
    util: util.toString(),
  };
};

export const addressMapping = (data, type) => {
  return data.map(obj => {
    const mapping =
      type === PRODUCT.ELECTRICITY ? electricityMapping(obj) : gasMapping(obj);

    return {
      address: {
        streetNumber: mapping.streetNumber,
        streetName: `${mapping.streetName}${mapping.streetName}`,
        streetType: mapping.streetType,
        state: mapping.state,
        postcode: mapping.postCode,
        suburb: mapping.suburb,
        unstructured_line_1: mapping.unstructuredLine1,
        unstructured_line_2: mapping.unstructuredLine2,
        unstructured_line_3: mapping.unstructuredLine3,
      },
      siteIdentifier: mapping.util,
      formattedAddress: mapping.formattedAddress,
      productType: type,
      multilineAddress: [
        ...(mapping.apartmentNumber
          ? [`${mapping.apartmentType} ${mapping.apartmentNumber}`]
          : []),
        `${mapping.streetNumber}${mapping.streetNumberSuffix} ${
          mapping.streetName
        } ${mapping.streetType}${
          mapping.streetTypeSuffix ? ` ${mapping.streetTypeSuffix}` : ''
        }`,
        `${mapping.suburb}`,
        `${mapping.state} ${mapping.postCode}`,
      ],
    };
  });
};

// Not sure why only this lookup structure is different from others
export const referrerMap = dataArr => {
  return dataArr.map(ref => ({
    code: ref.id,
    display: ref.description,
    value: ref.id,
  }));
};
export const lookupMap = data => {
  const dataEntries = Object.entries(data);
  return dataEntries.map(([key, value]) => ({
    code: key,
    display: value,
    icon: null,
    value: key,
  }));
};

const propertySetter = code => {
  const { value = null } = propertyTypes.find(x => x.code === code) || {};
  return value;
};

const removeCountryCode = phoneNumber => {
  if (phoneNumber.startsWith('61')) {
    return `0${phoneNumber.substring(2)}`;
  }
  return phoneNumber;
};

// Map Customer to Contact from the API
export const contactMap = ({
  emails: [email = {}],
  phones = [],
  ...otherData
}) => {
  return {
    id: otherData.id,
    title: otherData.title,
    firstName: otherData.first_name,
    lastName: otherData.last_name,
    middleName: otherData.middle_name,
    emailAddress: email ? email.email : '',
    emailId: email ? email.id : null,
    contactPreference: '',
    dateOfBirth: otherData.date_of_birth,
    phones: phones.map(_phone => ({
      id: _phone.id,
      type: _phone.type,
      isPrimary: _phone.is_primary,
      phoneNumber: removeCountryCode(_phone.phone_number),
    })),
    isPrimary: otherData.is_primary,
  };
};

export const identificationMap = data => {
  if (!data) return [];
  return [
    {
      id: data.id,
      identificationExpiry: data.expires_on,
      identificationNumber: data.reference,
      identificationType: data.type,
    },
  ];
};

export const concessionCardMap = data => {
  if (!data) return null;
  return {
    id: data.id,
    startDate: data.issued_on,
    expiryDate: data.expires_on,
    cardTypeCode: data.concession,
    cardNumber: data.reference,
    cardName: data.first_name,
    cardLastName: data.last_name,
    hasConsented: false,
  };
};

export const serviceMap = service => {
  const productType = Object.entries(productsMap).find(
    ([key, value]) => value === service.fuel_id
  );

  return {
    id: service.id,
    siteIdentifier: service.identifier,
    selectedGreenpower: service.selectedGreenpower,
    productType: productType ? productType[0] : null,
    productOffering: {
      offeringCode: service.siteOffer ? service.siteOffer.offer_id : null,
      pricePlanCode: '',
      usageCategory: 'MONTHLY',
      paymentFrequency: 'MONTHLY',
      estimatedCost: 0,
    },
    hasConnection: null,
    billingFrequency: service.bill_frequency,
    estimatedMonthlyBill: service.estimated_monthly_bill,
    hasBasicMeter: service.has_basic_meter === 1 ? true : false,
    hasSolarPanels: service.has_solar === 1 ? true : false,
    solarType: service.feed_in_type_id,
    vsiAppointmentTime: service.vsi_time,
    vsiAccessMethod: service.vsi_method,
    meterAccess: service.transfer_instructions,
  };
};

const getLifeSupportType = (hasEleLifeSupport, hasGasLifeSupport) => {
  if (hasEleLifeSupport && hasGasLifeSupport) {
    return 'D';
  }
  if (hasEleLifeSupport) {
    return 'E';
  }
  if (hasGasLifeSupport) {
    return 'G';
  }
  return '';
};

const getStreetAddress = address => {
  if (!isEmpty(address.unstructured_line_1)) {
    return address.unstructured_line_1;
  }
  return `${address.StreetNumber}${address.StreetNumberSuffix} ${address.StreetName} ${address.StreetType}`;
};

/**
 * Map API response data from Uconx,
 * to Zappy's local state
 */
export const signupMap = ({
  customers = [],
  id,
  current_page = '',
  sites = [],
  hash,
  referral_id,
  extra_fields,
  property_type,
  type: transferType,
  address = {},
  directDebit,
  email_invoice,
  email_notice,
  promo_code,
  organisation = null,
  ...data
}) => {
  // UCX-1715 Zappy quote looking for Gas plan but only Elec offer was selected for DF customer
  sites = sites.filter(site => site.siteOffer !== null);
  const [
    { identifications, allow_marketing } = {
      identifications: [],
      allow_marketing: 0,
    },
  ] = customers;
  const [
    {
      siteOffer,
      address: siteAddress,
      lifeSupport,
      has_medical_cooling,
      is_owner,
      move_in_terms_accepted,
    } = {
      siteOffer: {},
      siteAddress: {},
      lifeSupport: null,
      has_medical_cooling: null,
      is_owner: null,
      move_in_terms_accepted: 0,
    },
  ] = sites;
  let concessionCard = identifications.find(x => x.type === 'Concession');

  if (!unselectHasConcessionCard && concessionCard === undefined) {
    concessionCard = null;
  }

  const identificationData = identifications.find(x => x.type !== 'Concession');
  const moveInDate = siteOffer ? siteOffer.start_date : '';
  const hasDifferentAddress = !isEmpty(address);
  const extras = JSON.parse(extra_fields);

  const hasEleLifeSupport = sites.find(
    x => x.fuel_id === productsMap.POWER && !isEmpty(x.lifeSupport)
  );
  const hasGasLifeSupport = sites.find(
    x => x.fuel_id === productsMap.GAS && !isEmpty(x.lifeSupport)
  );
  const _data = {
    ...defaultSignupObject,
    ...(id && { id: id }),
    applicationId: hash,
    apiCode: extras?.api_code,
    contacts: customers.map(contactMap),
    currentPage: current_page,
    propertyType: property_type,
    address: siteAddress,
    concessionCardDetail: concessionCardMap(concessionCard),
    identifications: identificationMap(identificationData),
    hasConcessionCard:
      concessionCard !== undefined ? !isEmpty(concessionCard) : null,
    hasAdditionalContacts: customers.length > 1,
    hasCoolingConcession:
      has_medical_cooling !== null
        ? booleanConverter(has_medical_cooling)
        : null,
    services: sites.map(_site => serviceMap(_site)),
    hasLifeSupportPerson: hasEleLifeSupport || hasGasLifeSupport ? 'yes' : 'no',
    lifeSupportPersonDetail: lifeSupport ? lifeSupport.description : null,
    lifeSupportType: getLifeSupportType(hasEleLifeSupport, hasGasLifeSupport),
    referralSource: referral_id,
    transferDetail: {
      transferType: transferType,
      // if cut off date has passed, clear the date, force the customer to choose a new date UC-2505
      transferDate: moveInDate
        ? new Date(moveInDate) < new Date()
          ? null
          : moveInDate
        : null,
      acceptMoveInTerms: booleanConverter(move_in_terms_accepted),
    },
    promotionCode: promo_code,
    propertyOwner: is_owner !== null ? !!is_owner : null,
    hasDifferentPostalAddress: hasDifferentAddress,
    correspondenceDelivery:
      email_invoice === null ? null : email_invoice ? 'Email' : 'Post',
    sameDeliveryMethod:
      email_notice === null ? null : email_notice ? 'yes' : 'no',
    postalAddress: hasDifferentAddress
      ? {
          id: address.id,
          streetAddress: getAddressLineOneFromAddress(address),
          suburb: address.Suburb,
          postcode: address.PostCode,
          state: address.State,
        }
      : {},
    paymentDetails: !isEmpty(directDebit)
      ? paymentDetailsMap(directDebit)
      : null,
    acceptedMarketingTerms: booleanConverter(allow_marketing),
    organisation: organisation,
  };
  return _data;
};

export const paymentDetailsMap = directDebit => {
  let data = null;

  if (isEmpty(directDebit.bsb) && !isEmpty(directDebit.token)) {
    data = {
      creditCard: {
        ...(directDebit.id && { id: directDebit.id }),
        cardholderName: directDebit.name,
        cardNumber: directDebit.masked_card_number,
        cardType: directDebit.card_type,
        cardExpiry: directDebit.card_expiry,
      },
      paymentMethod: PAYMENT_METHOD_TYPE_CARD,
    };
  } else if (!isEmpty(directDebit.bsb) && isEmpty(directDebit.token)) {
    data = {
      bankAccount: {
        ...(directDebit.id && { id: directDebit.id }),
        acceptedTermsAndConditions: true,
        authorization: true,
        signingAuthority: true,
        accountName: directDebit.name,
        accountNumber: directDebit.account_number,
        bankStateBranchNumber: directDebit.bsb,
        bankName: directDebit.bank_name,
      },
      paymentMethod: PAYMENT_METHOD_TYPE_ACCOUNT,
    };
  }

  return data;
};

// // TODO THIS IS FOR SITE DETAILS API CALL
// export const siteMap = (data, productType) => {
//   const { street_address = '' } = data.address
//   return {
//     siteIdentifier: data.identifier,
//     productType: productType, // TODO do a map to find code
//     multilineAddress: street_address.split(', '),
//     formattedAddress: street_address
//   }
// }

const siteIdSetter = (siteId, productType) => {
  return siteId && siteId.length === 11 && productType === PRODUCT.GAS
    ? siteId.slice(0, -1)
    : siteId;
};

/* REQUEST MAPPER */
export const offerRequestMap = (siteId, data) => {
  if (siteId === null) {
    const { agentEnteredCode } = cookie.get();
    return {
      distributor_id: data.distributor_id,
      market_segment_id: data.market_segment_id,
      postcode: data.postcode,
      api_code: agentEnteredCode || process.env.REACT_APP_API_USER_ID,
      promo: data.promo,
      has_feed_in_meter: data.includeSolar === true ? '1' : '0',
      sort: data.sortBy,
    };
  }

  return {
    [data.productType === PRODUCT.ELECTRICITY ? 'nmi' : 'mirn']: siteIdSetter(
      siteId,
      data.productType
    ),
    customer_classification_code: propertySetter(data.propertyType),
    api_code: data.apiCode,
    promo: data.promo,
    has_feed_in_meter: data.includeSolar === true ? '1' : '0',
    sort: data.sortBy,
  };
};

export const requestSiteDetails = ({ hash, account_id }) => {
  return {
    hash,
    account_id,
  };
};

export const summaryMap = data => {
  const {
    sites: [site = { address: {} }],
    reference,
    directDebit,
    id,
    promo_code,
    user_id,
  } = data;
  const services = data.sites.map(site => {
    return {
      name: 'Service',
      value: `${productsMap.POWER === site.fuel_id ? 'Electricity' : 'Gas'}: ${
        site.siteOffer.offer_name
      }`,
    };
  });

  return {
    id,
    address: site.address.street_address,
    isAgentSale:
      user_id !== process.env.REACT_APP_API_USER_ID && user_id !== 99999,
    summaryDetails: [
      { name: 'Reference No.', value: reference },
      { name: 'Connection Date', value: '' },
      {
        name: 'Payment option',
        value: !isEmpty(directDebit) ? 'Direct Debit' : 'Manual',
      },
      { name: 'Life Support', value: '' },
      { name: 'Promo code', value: promo_code },
    ].concat(services),
  };
};

/* INPUT MAPPER */
// Map Contacts to customers to send to API
export const inputContactMap = (
  data,
  {
    concessionCardDetail: concessionCard,
    identification = {},
    acceptedMarketingTerms = false,
  }
) => {
  return {
    ...(data.id && { id: data.id }),
    title: data.title,
    is_primary: data.isPrimary,
    first_name: data.firstName,
    middle_name: data.middleName,
    last_name: data.lastName,
    date_of_birth: data.dateOfBirth,
    allow_marketing: booleanConverter(acceptedMarketingTerms),
    phones: data.phones.map(phone => ({
      ...(phone.id && { id: phone.id }),
      is_primary: phone.isPrimary,
      type: phone.type,
      phone_number: phone.phoneNumber,
    })),
    identifications: [
      ...(!isEmpty(concessionCard) && data.isPrimary
        ? [
            {
              ...(concessionCard.id && { id: concessionCard.id }),
              issued_on: concessionCard.startDate,
              expires_on: concessionCard.expiryDate,
              concession: concessionCard.cardTypeCode,
              reference: concessionCard.cardNumber,
              first_name: concessionCard.firstName,
              last_name: concessionCard.lastName,
              type: 'Concession',
            },
          ]
        : []),
      ...(!isEmpty(identification) && data.isPrimary
        ? [
            {
              id: identification.id,
              expires_on: identification.identificationExpiry,
              concession: '',
              reference: identification.identificationNumber,
              type: identification.identificationType,
            },
          ]
        : []),
    ],
    ...(data.emailAddress && {
      emails: [
        {
          ...(data.emailId && { id: data.emailId }),
          email: data.emailAddress,
        },
      ],
    }),
  };
};

/**
 * Format Zappy's state data into
 * Uconx's format
 */
export const inputSignupMap = ({
  propertyType,
  applicationId,
  services = [],
  currentPage,
  contacts = [],
  id,
  concessionCardDetail = {},
  identifications = [],
  hasCoolingConcession,
  transferDetail,
  postalAddress = {},
  hasDifferentPostalAddress,
  referralSource,
  propertyOwner,
  paymentDetails,
  correspondenceDelivery = null,
  sameDeliveryMethod = null,
  promotionCode,
  apiCode,
  trackingCampaign,
  trackingMedium,
  trackingSource,
  hasLifeSupportPerson,
  lifeSupportPersonDetail,
  lifeSupportType,
  acceptedMarketingTerms,
  acceptedHasInterestOnInterenet,
  acceptedContractTerms,
  acceptedInfoTerms,
  acceptedPrivacyTerms,
  acceptedCreditReport,
  acceptedEICTerms,
  acceptedRequiresSmartMeterUpgrade,
  organisation = null,
  googleAddress,
}) => {
  const { transferType = TRANSFER_TYPE.TRANSFER, transferDate = null } =
    transferDetail || {};
  const { paymentMethod, bankAccount, creditCard } = paymentDetails || {};
  const acceptMoveInTerms =
    transferType === TRANSFER_TYPE.MOVE_IN
      ? transferDetail?.acceptMoveInTerms
      : false;
  return {
    ...(id && { id }),
    ...(applicationId && { hash: applicationId }),
    ...(hasDifferentPostalAddress &&
      !isEmpty(postalAddress) && {
        address: {
          ...(postalAddress.id && { id: postalAddress.id }),
          unstructured_line_1: postalAddress.streetAddress,
          Suburb: postalAddress.suburb,
          PostCode: postalAddress.postcode,
          State: postalAddress.state,
        },
      }),
    current_page: currentPage,
    property_type: propertyType,
    type: transferType,
    promo_code: promotionCode,
    api_code: apiCode,
    tracking_campaign: trackingCampaign,
    tracking_medium: trackingMedium,
    tracking_source: trackingSource,
    sites: services.map(_site =>
      inputSite(_site, {
        hasCoolingConcession,
        transferDate,
        propertyOwner,
        hasLifeSupportPerson,
        lifeSupportPersonDetail,
        lifeSupportType,
        acceptMoveInTerms,
        googleAddress,
      })
    ),
    referral_id: referralSource,
    marketing_accepted: booleanConverter(acceptedMarketingTerms),
    terms_accepted: booleanConverter(acceptedContractTerms),
    important_info_accepted: booleanConverter(acceptedInfoTerms),
    privacy_policy_accepted: booleanConverter(acceptedPrivacyTerms),
    credit_agreement_accepted: booleanConverter(acceptedCreditReport || true),
    smart_meter_upgrade_accepted: acceptedRequiresSmartMeterUpgrade,
    eic_accepted: booleanConverter(acceptedEICTerms),
    has_interest_on_internet: booleanConverter(acceptedHasInterestOnInterenet),
    customers: contacts.map((cust, index) =>
      inputContactMap(cust, {
        concessionCardDetail,
        identification: identifications.length ? identifications[index] : {},
        acceptedMarketingTerms,
      })
    ),
    directDebit: null,
    ...(paymentMethod === PAYMENT_METHOD_TYPE_ACCOUNT &&
      !isEmpty(bankAccount) && {
        directDebit: inputDirectDebit(bankAccount),
      }),
    ...(paymentMethod === PAYMENT_METHOD_TYPE_CARD &&
      !isEmpty(creditCard) && {
        directDebit: inputCreditCard(creditCard),
      }),
    email_invoice:
      correspondenceDelivery === null
        ? 1
        : correspondenceDelivery === 'Email'
        ? 1
        : 0,
    email_notice:
      sameDeliveryMethod === null ? 1 : sameDeliveryMethod === 'yes' ? 1 : 0,
    organisation: organisation,
  };
};

export const inputDirectDebit = ({
  bankStateBranchNumber,
  accountName,
  accountNumber,
  bankName,
  id,
}) => {
  return {
    ...(id && { id }),
    bank_name: bankName,
    bsb: bankStateBranchNumber,
    name: accountName,
    account_number: accountNumber,
  };
};

export const inputCreditCard = ({
  cardHolderName,
  cardType,
  cardExpiry,
  id,
}) => {
  return {
    ...(id && { id }),
    name: cardHolderName,
    card_type: cardType,
    card_expiry: cardExpiry,
  };
};

export const inputSite = (
  site,
  {
    hasCoolingConcession,
    transferDate,
    propertyOwner,
    hasLifeSupportPerson,
    lifeSupportPersonDetail,
    lifeSupportType,
    acceptMoveInTerms,
    googleAddress,
  }
) => {
  return {
    identifier: site.siteIdentifier,
    fuel_id: productsMap[site.productType],
    bill_frequency: site.billingFrequency,
    estimated_monthly_bill: site.estimatedMonthlyBill,
    has_medical_cooling: booleanConverter(hasCoolingConcession),
    move_in_terms_accepted: booleanConverter(acceptMoveInTerms),
    latitude: googleAddress?.Latitude,
    longitude: googleAddress?.Longitude,
    selected_greenpower_id: site.selectedGreenpowerId,
    feed_in_type_id: site.solarType,
    has_solar: site.hasSolarPanels ? 1 : 0,
    is_owner: typeof propertyOwner !== 'boolean' ? null : propertyOwner ? 1 : 0,
    ...(site.id && { id: site.id }),
    ...(site.productOffering.offeringCode && {
      siteOffer: {
        offer_id: site.productOffering.offeringCode,
        start_date: transferDate,
        terms_and_conditions_id: site.termsAndConditionsId,
      },
    }),
    ...(hasLifeSupportPerson === 'yes' &&
      (lifeSupportType === 'D' ||
        lifeSupportType === LIFE_SUPPORT_TYPE[site.productType]) && {
        lifeSupport: {
          start_date: null,
          description: lifeSupportPersonDetail,
        },
      }),
    vsi_time: site.vsiAppointmentTime,
    vsi_method: site.vsiAccessMethod,
    transfer_instructions: site.meterAccess,
  };
};

/**
 * Converts the data into a boolean or null.
 * Will return null if the data is not a boolean or number (eg, if it's already null)
 */
const booleanConverter = (data = false) => {
  const dataType = typeof data;
  if (dataType === 'boolean') {
    return data ? 1 : 0;
  }
  return dataType !== 'number' ? null : !!data;
};
