export const EMBEDDED_SEARCH_API = 'v2/sites/search-embedded-network';
export const EMBEDDED_GET_SITES_API = 'v2/sites/embedded-networks/{code}';

export const PRODUCT_LIST_API = 'v2/lookups/product-types';
export const PROMO_CODE_VALIDATE_API = 'v2/promo-code/check-code';
export const AGENT_LOGIN_VALIDATION_API = 'v2/user';
export const OFFER_LIST_API = 'v2/pricing/new';
export const GAS_SEARCH_SITE_IDENTIFIER_API = 'v2/mirn-lookup';
export const POWER_SEARCH_SITE_IDENTIFIER_API = 'v2/nmi-lookup';
export const GET_SINGLE_SITE_ADDRESS = 'v2/sites/{id}';
export const PROPERTY_LIST_API = 'v2/lookups/customer-types';
export const PRICE_FILTER_API = 'v2/price-filters';
export const STATE_LIST_API = 'v2/suburb';
export const OFFER_AVAILABLE = 'v2/offer/availability';
export const METER_COORDINATORS_LIST_API = 'v2/lookups/meter-coordinators';
export const IDENTIFICATION_TYPE_API = 'v2/identification';
export const SIGNUP_API = 'v2/accounts';
export const SIGNUP_SUBMIT_API = 'v2/signup/create';
export const REQUEST_PARK_PROGRESS = 'v2/account/send';
export const REQUEST_MOBILE_AUTHENTICATION = 'v2/account/mobile-authentication';
export const CHECK_MOBILE_CODE = 'v2/account/check-mobile-code';
export const LOG_D2D_ACTIVITY = 'v2/account/log';
export const CUSTOMER_LOG_ACTIVITY = 'v2/account/customer-log';
export const RESUME_SIGNUP_VALIDATION_API = 'v2/account/unlock';
export const REFERRAL_NAME_API = 'v2/referral/name';
export const OFFER_DOCUMENT_API = 'v2/offer-document';
export const FACTSHEET_API = 'v2/factsheet';

export const PHONE_VALIDATION_API = 'v2/validate/phone';
export const EMAIL_VALIDATION_API = 'v2/validate/email';

export const SOLAR_TYPE_LIST_API = 'v2/feed-in-type';

export const INDUSTRY_LIST_API = 'v2/lookups/industry-types';
export const SEARCH_BUSINESS_NAME_API = 'v2/abn-lookup/search-business-name';
export const SEARCH_ABN_NUMBER_API = 'v2/abn-lookup/search-abn';
export const SEARCH_ACN_NUMBER_API = 'v2/abn-lookup/search-acn';
export const SALUTATION_LIST_API = 'v2/customer-titles';
export const CARD_LIST_API = 'v2/concession';
export const PREFER_HEARING_API = 'v2/referrers';
export const CONCESSION_CARD_VALIDATE_API =
  'v2/signup/concession-card/validate';
export const VALIDATE_BANK_ACCOUNT_NUMBER = 'v2/signup/bank-account/validate';
export const FETCH_MOVE_IN_DATES_API = 'v2/move-in-date';

export const TOKEN_VERIFICATION_API = 'v2/token-verification';
export const SET_PASSWORD_API = 'v2/set-password';

export const COST_FREQUENCY_LIST_API =
  'v2/product-offerings/pricing-options/cost-frequency';
export const USAGE_CATEGORIES_API =
  'v2/product-offerings/pricing-options/usage-categories';
export const PAYMENT_FREQUENCY_LIST_API = 'v2/lookups/payment-frequency';
export const GET_BILLING_FREQUENCY_API = 'v2/lookups/billing-frequency';
export const GET_PLAN_DETAILS_API = 'v2/site/offer-new';
export const VALIDATE_PIN_CODE = 'v2/signup/{applicationId}/validatecode';
export const REQUEST_PIN_CODE_EMAIL =
  'v2/signup/{applicationId}/sendverifycode';

//GET PAYMENT VENDOR LIST
export const GET_PAYMENT_VENDOR_LIST_API = 'api/Request/signup-token/vendors';
export const GENERATE_PAYMENT_REQUEST_API =
  'api/Request/{vendor}/signup-token/{signUpId}';

export const VALIDATE_SIGNUP_URL_API = 'v2/signup/resume';

// Address lookup
export const SEARCH_ADDRESS_API = 'v2/address-autocomplete/search';
export const GET_ADDRESS_DETAILS_API = 'v2/address-autocomplete/get-details';

export const GET_LOCATION_API = 'v2/location';
export const GET_DISTRIBUTORS_API = 'v2/distributor';

// Static constants
export const GOOGLE_LOCATION_API_URL = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&libraries=places`;
export const INTERNAL_SERVER_ERROR =
  'Some error occurs, please try after sometime';
export const PAYMENT_METHOD_TYPE_CARD = 'CRDCARD';
export const PAYMENT_METHOD_TYPE_ACCOUNT = 'DIRECT';
export const PAYMENT_METHOD_TYPE_CHEQUE = 'CHEQUE';

export const DATE_FORMAT_USER = 'dd/MM/yyyy';

export const APPLICATION_ID = 'applicationId';
export const ACCOUNT_ID = 'accountId';
export const SIGNUP_BY_SALE = 'signupBySale';

export const PRODUCT = {
  ELECTRICITY: 'POWER',
  GAS: 'GAS',
  BOTH: 'BOTH',
};

export const PROPERTY = {
  RESIDENT: 'RESIDENT',
  COMPANY: 'COMPANY',
};

export const SIGNUP_STEPS = {
  selectedPlan: 'selected-plan',
  yourPlan: 'your-plan',
  yourCompanyDetails: 'your-company-details',
  yourDetails: 'your-details',
  mobileAuthentication: 'mobile-authentication',
  yourProperty: 'your-property',
  yourIdentification: 'your-identification',
  aboutYou: 'about-you',
  paymentMethod: 'payment-method',
  paymentDetails: 'payment-details',
  lifeSupportConcession: 'life-support-concession-details',
  completeSummary: 'complete-summary',
};
export const SIGNUP_SUB_URLS = [
  SIGNUP_STEPS.selectedPlan,
  SIGNUP_STEPS.yourCompanyDetails,
  SIGNUP_STEPS.yourDetails,
  SIGNUP_STEPS.yourProperty,
  SIGNUP_STEPS.yourIdentification,
  SIGNUP_STEPS.paymentMethod,
  SIGNUP_STEPS.paymentDetails,
  SIGNUP_STEPS.lifeSupportConcession,
  SIGNUP_STEPS.completeSummary,
];

export const PRICE_FILTER_DEFAULT_USAGE = 'LOW';
export const PRICE_FILTER_DEFAULT_FREQUENCY = 'MONTHLY';

export const DEFAULT_IMAGE_URL = 'default-img.jpg';

export const productsMap = {
  POWER: 1,
  GAS: 2,
};

export const LIFE_SUPPORT_TYPE = {
  POWER: 'E',
  GAS: 'G',
};

export const RECEIVE_BILL = {
  EMAIL: 'Email',
  POST: 'Post',
};

export const TRANSFER_TYPE = {
  TRANSFER: 'Transfer',
  MOVE_IN: 'MoveIn',
};

export const SAME_DELIVERY_METHOD = {
  YES: 'yes',
  NO: 'no',
};

export const USE_ESTIMATED_READ_FOR_BILLING = {
  YES: 'yes',
  NO: 'no',
};

export const BILL_CYCLE_CODES = {
  MONTHLY: 'MONTHLY',
  QUARTERLY: 'QUARTERLY',
};

export const TANGO_POWER_BILL_CYCLE_CODES = {
  MONTHLY: 'MONTHLY',
  QUARTERLY: 'QUARTERLY',
};
export const TANGO_GAS_BILL_CYCLE_CODES = {
  MONTHLY: 'GAS_MONTHLY',
  QUARTERLY: 'GAS',
};

// TODO REMOVE HARDCODE
export const productTypes = [
  {
    code: 'POWER',
    color: null,
    display: 'Electricity',
    displayAlternative: 'NMI',
    icon: 'bolt',
    isDefault: false,
    value: 'POWER',
  },
];

export const STREET_SUFFIX = [
  'CN',
  'E',
  'EX',
  'LR',
  'N',
  'NE',
  'NW',
  'S',
  'SE',
  'SW',
  'UP',
  'W',
];

export const STREET_TYPE = [
  'ACCS',
  'ALLY',
  'ALWY',
  'AMBL',
  'ANCG',
  'ANT',
  'APP',
  'ARC',
  'ART',
  'ATM',
  'AVE',
  'BANK',
  'BASN',
  'BASN',
  'BAY',
  'BBQ',
  'BCH',
  'BDGE',
  'BDWY',
  'BEND',
  'BLCK',
  'BLDG',
  'BLK',
  'BNGW',
  'BOWL',
  'BRAE',
  'BRCE',
  'BRK',
  'BROW',
  'BTSD',
  'BVD',
  'BLVD', // 31 Marley Boulevard, Doreen VIC, Australia
  'BVDE',
  'BWLK',
  'BYPA',
  'BYWY',
  'CAGE',
  'CARP',
  'CARS',
  'CAUS',
  'CCT',
  'CIRCUIT', // 33 Moishe Circuit, Springvale
  'CDS',
  'CH',
  'CIR',
  'CL',
  'CLDE',
  'CLT',
  'CLUB',
  'CMMN',
  'CNN',
  'CNR',
  'CNWY',
  'CON',
  'COOL',
  'COVE',
  'COWY',
  'CPS',
  'CRCS',
  'CRD',
  'CRES',
  'CRSE',
  'CRSG',
  'CRSS',
  'CRST',
  'CSO',
  'CT',
  'CTR',
  'CTTG',
  'CTYD',
  'CUWY',
  'DALE',
  'DELL',
  'DEVN',
  'DIP',
  'DOCK',
  'DR',
  'DRWY',
  'DSTR',
  'DUPL',
  'DWNS',
  'EDGE',
  'ELB',
  'END',
  'ENT',
  'ESP',
  'EST',
  'EXP',
  'EXTN',
  'FAWY',
  'FCTY',
  'FITR',
  'FK',
  'FLAT',
  'FOLW',
  'FORM',
  'FRNT',
  'FRTG',
  'FSHR',
  'FTRK',
  'FTWY',
  'FWY',
  'GAP',
  'GDN',
  'GDNS',
  'GLD',
  'GLEN',
  'GLY',
  'GR',
  'GRA',
  'GRGE',
  'GRN',
  'GRND',
  'GTE',
  'GTES',
  'GTWY',
  'HALL',
  'HETH',
  'HILL',
  'HRD',
  'HTS',
  'HUB',
  'HWY',
  'INLT',
  'INTG',
  'INTN',
  'ISLD',
  'JNC',
  'KEY',
  'KEYS',
  'KNOL',
  'LA',
  'LN',
  'LANE',
  'LBBY',
  'LDG',
  'LEES',
  'LINE',
  'LINK',
  'LKT',
  'LNWY',
  'LOFT',
  'LOOP',
  'LOT',
  'LSE',
  'LT',
  'LWR',
  'MALL',
  'MANR',
  'MART',
  'MBTH',
  'MEW',
  'MEWS',
  'MILE',
  'MNDR',
  'MT',
  'MWY',
  'NOOK',
  'OFFC',
  'OTLK',
  'OTLT',
  'OVAL',
  'PARK',
  'PART',
  'PASS',
  'PATH',
  'PDE',
  'PHWY',
  'PIAZ',
  'PKLD',
  'PKT',
  'PKWY',
  'PL',
  'PLAT',
  'PLM',
  'PLZA',
  'PNT',
  'PORT',
  'PROM',
  'PRST',
  'PSGE',
  'QDGL',
  'QDRT',
  'QUAD',
  'QY',
  'QYS',
  'RAMP',
  'RCH',
  'RD',
  'RDGE',
  'RDS',
  'RDSD',
  'RDWY',
  'REAR',
  'RES',
  'REST',
  'RGWY',
  'RIDE',
  'RING',
  'RISE',
  'RMBL',
  'RND',
  'RNDE',
  'RNGE',
  'ROOM',
  'ROW',
  'ROWE',
  'ROWY',
  'RSBL',
  'RTE',
  'RTRN',
  'RTT',
  'RTY',
  'RUE',
  'RUN',
  'RVR',
  'RVRA',
  'RVWY',
  'SBWY',
  'SDNG',
  'SEC',
  'SHRM',
  'SHWY',
  'SIGN',
  'SLPE',
  'SND',
  'SPUR',
  'SQ',
  'ST',
  'STAI',
  'STH',
  'STLL',
  'STOR',
  'STPS',
  'STR',
  'STRA',
  'STRP',
  'STRS',
  'STRT',
  'SUBS',
  'SWY',
  'TARN',
  'TERRACE', // 28 Bain Terrace, Trevallyn TAS, Australia
  'TCE',
  'THOR',
  'TKWY',
  'TLWY',
  'TMWY',
  'TNCY',
  'TOP',
  'TOR',
  'TRI',
  'TRK',
  'TRL',
  'TRLR',
  'TURN',
  'TWR',
  'TWRS',
  'UNIT',
  'UPAS',
  'UPR',
  'VALE',
  'VDCT',
  'VIEW',
  'VLGE',
  'VLL',
  'VLLA',
  'VLLA',
  'VLT',
  'VLT',
  'VSTA',
  'VSTA',
  'VWS',
  'WADE',
  'WALK',
  'WAY',
  'WY', // 23 Lemon Myrtle Way, Craigieburn VIC 3064
  'WHRF',
  'WHSE',
  'WKSH',
  'WKWY',
  'WOOD',
  'WTRS',
  'WTWY',
  'WYND',
  'YARD',
];

// Map exotic street types to their MSATs equivalents
export const STREET_TYPE_MAPPINGS = [{ places: 'BLVD', msats: 'Bvd' }];
