/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import React, { Fragment } from 'react';
import i18n from 'i18next';
import './compare-plan.scss';
import ProductPlans from './Components/ProductPlans/container';
import PlanFilter from './Components/PlanFilter';
import { object, array, func, bool } from 'prop-types';
import {
  AgilityNoRecord,
  AgilityInfoDialog,
  AgilityButton,
  AgilityTypography,
  AgilityInfo,
} from 'Src/AgilityComponents';
import queryString from 'query-string';
import {
  getDistributorByProductCode,
  getProductTypeByProductCode,
  getReferFromCookie,
  isEmpty,
  setSaveProgressData,
  getStateFromId,
} from 'App/utils/helper';
import { AppBar, Hidden, Container } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  APPLICATION_ID,
  ACCOUNT_ID,
  SIGNUP_STEPS as signupSteps,
  PRODUCT,
} from 'App/utils/constants';
import { withStyles } from '@material-ui/core/styles';
import cookie from 'js-cookie';
import { Helmet } from 'react-helmet';
import { withSnackbar } from 'notistack';
import { isPostcode, isSiteIdentifier } from 'App/utils/validationHelper';
import { prefillPostcodeByIpLocation } from 'App/customConfig';
import { logSaleAction } from 'App/utils/geolocation';
import dompurify from 'isomorphic-dompurify';
const sanitizer = dompurify.sanitize;

const styles = theme => ({
  selectedPlanButtonWrapperUl: {
    fontFamily: theme.typography.headingFontFamily,
  },
  nextButtonSpinner: {
    'svg.MuiCircularProgress-svg': {
      color: theme.palette.primary.contrastText,
    },
  },
  DefaultImg: theme.images.zeroState,
  BannerImage: theme.images.banner,
  primaryDarkColor: {
    color: theme.palette.primary.dark,
  },
  grey: {
    color: theme.palette.grey[600],
  },
  primaryMainColor: {
    color: theme.palette.primary.main,
  },
  postcodeHeading: theme.custom.ComparePlan.postcodeHeading,
  postcodeHeadingOuter: theme.custom.ComparePlan.postcodeHeadingOuter,
});

class ComparePlan extends React.Component {
  constructor(props) {
    super(props);

    let qParams = queryString.parse(props.location.search);

    props.planFilterData.hasSolar = qParams.solar === '1' || false;
    if (qParams.cust_type) {
      switch (qParams.cust_type) {
        case 'sme':
          props.planFilterData.selectedProperty = 'COMPANY';
          break;
        case 'resi':
        default:
          props.planFilterData.selectedProperty = 'RESIDENT';
          break;
      }
    }

    if (qParams.promo && this.props.isNBE) {
      cookie.set('promo', qParams.promo, {
        expires: 0.5,
      });
    }

    if (qParams.ref) {
      cookie.set(
        'refer',
        JSON.stringify({
          campaign: 'Referral',
          medium: 'Website',
          source: qParams.ref,
        }),
        { expires: 28 }
      );
    }

    this.state = {
      selectedProducts: this.props.planFilterData.selectedProducts,
      showContactUsDialog: false,
      searchTrigger: true,
      searchClick: false, // Boolean state that show button contents or not
      searchLoading: false, // Loading state for address search
      proceedLoading: false, // Loading state for footer sticky bar
      preloadSite: '',
      postcode: qParams.postcode || '',
      nmi: qParams.nmi || '',
      cust_type: (qParams.cust_type === 'sme' ? 2 : 1) || 1,
      showPostcodeDialog: false,
      showUnservicableDialog: false,
      findingByPostcode: false,
      distributors: [],
      loadOffersNoClick: false,
    };

    let nmiPrefilled = !isEmpty(this.state.nmi) && this.props.isNBE;

    if (
      !nmiPrefilled &&
      !prefillPostcodeByIpLocation &&
      isEmpty(this.state.postcode)
    ) {
      // Apply default postcode
      this.state.loadOffersNoClick = true; // don't automatically search
      this.props.getLocation().then(location => {
        this.setState({ postcode: location.postcode });
        this.searchOfferByPostcode(location.postcode);
      });
    }

    if (!isEmpty(this.state.postcode)) {
      this.state.loadOffersNoClick = true;
      this.searchOfferByPostcode(this.state.postcode);
    }

    if (nmiPrefilled) {
      this.state.loadOffersNoClick = true;
      // Possibly there's a much nicer react way to do this, but we just need to wait for everything to be mounted etc.
      // Not 100% sure why the postcode isEmpty() above can get away without this, but this is working for now.
      let nmi = this.state.nmi;
      let searchOfferByNmi = this.searchOfferByNmi;
      window.setTimeout(function () {
        searchOfferByNmi(nmi);
      }, 100);
    }
  }

  searchOfferByNmi = nmi => {
    if (isSiteIdentifier(nmi)) {
      const planFilterData = { ...this.props.planFilterData };
      planFilterData['enteredSearchLocation'] = nmi;
      this.props.setPlanFilters(planFilterData);
    }
  };

  searchOfferByPostcode = postcode => {
    if (isPostcode(postcode)) {
      const planFilterData = { ...this.props.planFilterData };
      planFilterData['enteredSearchLocation'] = postcode;
      this.props.setPlanFilters(planFilterData);
    }
  };

  searchOffersNoClick = () => {
    this.setState({
      searchClick: false,
      searchTrigger: true,
      loadOffersNoClick: true,
    });
  };

  componentDidMount() {
    this.checkAndUpdateSearchClick();
    this.props.resetCurrentStep();
    this.bottomSticky();
    document.addEventListener('scroll', this.bottomSticky);

    const api_code = cookie.get('agentEnteredCode');
    if (api_code !== undefined) {
      this.props.validateAgentLogin({ api_code });
      logSaleAction({ api_code }, 'AGENT_AUTH_CHECK_AT_COMPARE_PLAN');
    }
  }

  componentWillUnmount() {
    document.removeEventListener('scroll', this.bottomSticky);
  }

  componentDidUpdate(prevProps, prevState) {
    if (!isEmpty(prevProps.planFilterData)) {
      const changedProperty =
        prevProps.planFilterData.selectedProperty !==
        this.props.planFilterData.selectedProperty;
      const changedSolar =
        prevProps.planFilterData.hasSolar !==
        this.props.planFilterData.hasSolar;
      if ((changedProperty || changedSolar) && !this.state.searchTrigger) {
        this.setState({
          searchTrigger: true,
        });
      }
      const changedSearchText =
        prevProps.planFilterData.enteredSearchLocation !==
        this.props.planFilterData.enteredSearchLocation;
      if (changedSearchText && this.state.loadOffersNoClick) {
        this.setState({
          loadOffersNoClick: false,
        });
        this.searchAction();
      }
    }
    if (prevProps.saveProgressTrigger !== this.props.saveProgressTrigger) {
      const sites = Object.entries(this.props.selectedSiteIdentifier).map(
        ([key, obj]) => ({
          identifier: obj.siteIdentifier,
          productType: key,
        })
      );
      const { selectedProperty } = this.props.planFilterData;
      const data = setSaveProgressData(
        this.props.progressData,
        this.props.signupAPIResponse,
        sites,
        selectedProperty
      );
      this.props.postSignupDetails(data, true).catch(error => {
        this.props.enqueueSnackbar(i18n.t('500.error.message'), {
          variant: 'error',
        });
      });
    }
  }

  checkAndUpdateSearchClick = () => {
    if (this.hasSearchToExecute()) {
      this.setState({
        searchClick: true,
        searchTrigger: false,
      });
    }
  };

  searchAction = () => {
    const {
      enteredSearchLocation,
      selectedSearchLocation,
      code,
      selectedProducts,
      hasSolar = false,
    } = this.props.planFilterData;

    if (
      (this.props.isEmbeddedNetwork &&
        (isEmpty(enteredSearchLocation) || !code)) ||
      (!this.props.isEmbeddedNetwork &&
        !isEmpty(selectedSearchLocation) &&
        isEmpty(selectedSearchLocation.StreetName))
    ) {
      this.props.enqueueSnackbar(i18n.t('searchidentifier.incorrect.address'), {
        variant: 'error',
      });
      return;
    }
    const selectedSiteIdentifier = { ...this.props.selectedSiteIdentifier };
    const selectedPlans = { ...this.props.selectedPlans };

    // Below clears out selected siteIdentifier when changing plans filter and then clicking search button
    let isProductChange = false;
    for (const key of Object.keys(selectedPlans)) {
      if (!selectedProducts.includes(key)) {
        delete selectedPlans[key];
        isProductChange = true;
      }
    }

    if (isProductChange) {
      this.props.setPlanSelectionAction(selectedPlans);
      isProductChange = false;
    }

    for (const key of Object.keys(selectedSiteIdentifier)) {
      if (!selectedProducts.includes(key)) {
        delete selectedSiteIdentifier[key];
        isProductChange = true;
      }
      if (
        !Object.keys(this.props.allPlans).includes(key) ||
        this.props.allPlans[key].length === 0
      ) {
        delete selectedSiteIdentifier[key];
        isProductChange = true;
      }
    }

    if (isProductChange) {
      this.props.setIdentifierSelectionAction(selectedSiteIdentifier);
    }

    const state = {
      searchClick: true,
      searchLoading: false,
      selectedProducts: selectedProducts,
      findingByPostcode: false,
    };

    if (isPostcode(enteredSearchLocation)) {
      this.props
        .fetchDistributors(enteredSearchLocation || this.state.postcode)
        .then(result => {
          state.distributors = result;
          state.findingByPostcode = true;
          if (isEmpty(state.selectedProducts)) {
            state.selectedProducts = this.props.planFilterData.selectedProducts;
          }
          this.setState(state);
        });
    } else {
      this.setState(state);
    }
  };

  onProceedClick = () => {
    const { agentEnteredCode } = cookie.get();
    const data = { ...this.props.signupAPIResponse };
    const signupAPI = data.applicationId
      ? this.props.putSignupDetails
      : this.props.postSignupDetails;
    if (data.applicationId) {
      data.id = localStorage.getItem(ACCOUNT_ID);
      data.hash = localStorage.getItem(APPLICATION_ID);
    }
    data.unstructuredAddress = this.props.planFilterData.enteredSearchLocation;
    data.services = Object.keys(this.props.selectedPlans).map(key => {
      // const {productOfferingEstimates = [], productOfferingEstimate = {}} = this.props.selectedPlans[key]
      // const estimate = productOfferingEstimates.find(obj => obj.usageCategory === productOfferingEstimate.usageCategory);
      const productType = this.getProductDetails(key).code;
      const _id =
        data.services &&
        (data.services.find(x => x.productType === productType) || {}).id;
      return {
        ...(_id && { id: _id }),
        siteIdentifier: this.props.selectedSiteIdentifier[key].siteIdentifier,
        selectedGreenpowerId: this.props.selectedPlans[key].selectedGreenpower
          ? this.props.selectedPlans[key].selectedGreenpower.id
          : null,
        productType,
        productOffering: {
          offeringCode: this.props.selectedPlans[key].offeringCode,
          pricePlanCode: this.props.selectedPlans[key].pricePlanCode,
          // usageCategory: this.props.selectedPlans[key].productOfferingEstimate
          //   .usageCategory,
          // paymentFrequency: this.props.priceFilter[key].frequency,
          // estimatedCost: estimate ? estimate.estimatedCost : 0
        },
        hasSolarPanels:
          this.props.planFilterData.hasSolar &&
          productType === PRODUCT.ELECTRICITY,
        termsAndConditionsId:
          this.props.selectedPlans[key].termsAndConditionsId,
      };
    });

    data.propertyDetail = {
      hasSolarPanels: this.props.planFilterData.hasSolar || false,
    };
    data.propertyType = this.props.planFilterData.selectedProperty;

    let promoUsed = false;
    for (const [key, value] of Object.entries(this.props.selectedPlans)) {
      // value.promocode doesn't contain the actual code, but should be non null if this offer had a corresponding promocode
      if (value.promocode !== null) {
        promoUsed = true;
      }
    }
    data.promotionCode = promoUsed
      ? this.props.planFilterData.promoCode.code
      : '';

    if (agentEnteredCode) {
      data.apiCode = agentEnteredCode;
    } else if (process.env.REACT_APP_API_USER_ID) {
      // if one of the tango parters, set the API Code
      // to the partner's code for tracking purposes.
      data.apiCode = process.env.REACT_APP_API_USER_ID || '';
    }

    if (getReferFromCookie() !== null) {
      var refer = JSON.parse(cookie.get('refer'));
      data.trackingCampaign = refer.campaign;
      data.trackingMedium = refer.medium;
      data.trackingSource = refer.source;
    }

    // if D2D, show the offer again, otherwise (if direct sale)
    // just do to the relevent step
    if (agentEnteredCode) {
      data.currentPage = signupSteps.selectedPlan;
    } else {
      if (data.propertyType === 'COMPANY') {
        data.currentPage = signupSteps.yourCompanyDetails;
      } else {
        data.currentPage = signupSteps.yourDetails;
      }
    }

    data.googleAddress = this.props.planFilterData.selectedGoogleAddress;

    if (this.props.isEmbeddedNetwork) {
      if (this.props.planFilterData.code) {
        data.propertyDetail.embeddedNetworkCode =
          this.props.planFilterData.code;
      } else {
        this.props.enqueueSnackbar(
          i18n.t('embeddedNetwork.emptyCode.errorMsg'),
          { variant: 'error' }
        );
        return;
      }
    }

    // used for d2d geocoding
    data.googleAddress = this.props.planFilterData.selectedSearchLocation;

    this.showHideProceedLoading(true);
    signupAPI(data)
      .then(response => {
        this.showHideProceedLoading(false);
        this.props.history.push(`/user-signup/${response.current_page}`);
      })
      .catch(() => {
        this.showHideProceedLoading(false);
      });
  };

  showHideProceedLoading = loading => {
    this.setState({
      proceedLoading: loading,
    });
  };

  onCloseClickClick = () => {
    this.showHideServiceablePostcodeDialog(false);
    this.showHideUnServiceablePostcodeDialog(false);
    this.showHideContactUsDialog(false);
  };

  showHideContactUsDialog = show => {
    this.setState({
      showContactUsDialog: show,
    });
  };

  showHideUnServiceablePostcodeDialog = show => {
    this.setState({
      showUnservicableDialog: show,
    });
  };

  showHideServiceablePostcodeDialog = show => {
    this.setState({
      showPostcodeDialog: show,
    });
  };

  getProductDetails = productCode => {
    return getProductTypeByProductCode(this.props.productList, productCode);
  };

  getDistributor = productCode => {
    if (isEmpty(this.state.distributors)) {
      return;
    }
    return getDistributorByProductCode(this.state.distributors, productCode);
  };

  onClearLowerSection = () => {
    this.props.clearLowerSectionComparePlan();
    this.setState({
      selectedProducts: [],
    });
  };

  bottomSticky = () => {
    const stickyDivRef = document.getElementsByClassName(
      'selected-plan-button-wrapper'
    );
    const windowHeight =
      'innerHeight' in window
        ? window.innerHeight
        : document.documentElement.offsetHeight;
    const body = document.body;
    const html = document.documentElement;
    const docHeight = Math.max(
      body.scrollHeight,
      body.offsetHeight,
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight
    );
    if (document.getElementById('footer')) {
      const footerHeight = document.getElementById('footer').clientHeight;
      const windowBottom = windowHeight + window.pageYOffset + footerHeight;
      if (stickyDivRef && stickyDivRef[0]) {
        if (windowBottom > docHeight) {
          stickyDivRef[0].style.position = 'absolute';
        } else {
          stickyDivRef[0].style.position = 'fixed';
        }
      }
    }
  };

  onLocationSelected = () => {
    this.setState({
      searchClick: false,
      searchTrigger: true,
    });
  };

  hasSearchToExecute = () => {
    const { selectedProducts, enteredSearchLocation } =
      this.props.planFilterData;
    const { applicationId } = this.props.signupAPIResponse;
    return (
      !isEmpty(enteredSearchLocation) &&
      !isEmpty(selectedProducts) &&
      !isEmpty(applicationId)
    );
  };

  loadSavedData = (selectedIdentifier, filterData) => {
    if (!isEmpty(selectedIdentifier)) {
      // Set identifier from signupAPIResponse object
      this.props.setIdentifierSelectionAction(selectedIdentifier);
      // Change to like search button is clicked and prefill selectedProducts
      this.setState({
        selectedProducts: filterData.selectedProducts,
        preloadSite: filterData.enteredSearchLocation,
        searchClick: true,
      });
    }
  };

  allowScrollToProduct = productType => {
    if (this.state.selectedProducts.length === 2) {
      return productType === PRODUCT.ELECTRICITY;
    }

    return true;
  };

  titleCase = s => {
    return s && s[0].toUpperCase() + s.toLowerCase().slice(1);
  };

  getApexDomainUrl = () => {
    window.location = process.env.REACT_APP_WEBSITE_URL;
  };

  stateToPromoTermsKey = {
    VIC: 'compareplan.promo.terms.vic',
    NSW: 'compareplan.promo.terms.nsw-qld',
    QLD: 'compareplan.promo.terms.nsw-qld',
    TAS: 'compareplan.promo.terms.tas',
  };

  render() {
    const { classes } = this.props;

    const brand = this.titleCase(process.env.REACT_APP_BRAND_CODE);
    let parter = '';
    if (process.env.REACT_APP_PARTNER_BRAND_CODE) {
      parter = this.titleCase(process.env.REACT_APP_PARTNER_BRAND_CODE);
    }

    const dirName = `${brand}${parter}`;

    const DefaultImg = require(`App/themes/${dirName}/images/zero-state-compare-plan.png`);

    const powerPlanSelected =
      this.props.selectedPlans?.POWER?.hasOwnProperty('id');
    const gasPlanSelected = this.props.selectedPlans?.GAS?.hasOwnProperty('id');

    const state = getStateFromId(
      parseInt(this.state.distributors[0]?.['state_id'])
    );

    const statePromoTermsKey = this.stateToPromoTermsKey[state];

    return (
      <React.Fragment>
        <div id="landmark_beforeComparePlan"></div>
        <Helmet>
          <title>{`${
            this.state.searchClick ? 'Choose Plans' : 'Find a Plan'
          } | ${process.env.REACT_APP_RETAILER_NAME}`}</title>
        </Helmet>
        <div id="landmark_beforePlanFilter"></div>
        <PlanFilter
          searchLoading={this.state.searchLoading}
          data-test-id="tally-filter-id"
          onSearchClick={this.searchAction}
          onClearLowerSection={this.onClearLowerSection}
          onLocationSelected={this.onLocationSelected}
          isEmbeddedNetwork={this.props.isEmbeddedNetwork}
          loadSavedData={this.loadSavedData}
          agent={this.props.agent}
        />
        <div id="landmark_afterPlanFilter"></div>
        <section
          className={`plan-wrapper 
              ${
                !isEmpty(this.props.siteIdentifierList)
                  ? 'has-selected-offers'
                  : ''
              }
        `}
        >
          {this.state.searchClick === false && (
            <AgilityNoRecord>
              <AgilityTypography
                variant="h3"
                component="h3"
                align="center"
                className="mb-2"
              >
                {i18n.t('zeroState.compareplan.heading')}
              </AgilityTypography>
              <AgilityTypography
                variant="h4"
                component="h4"
                align="center"
                className="mb-2"
              >
                {i18n.t('zeroState.compareplan.message')}
              </AgilityTypography>
              <img
                css={{ maxHeight: '300px', maxWidth: '100%', height: 'auto' }}
                height="100%"
                src={DefaultImg}
                alt="Keep Searching"
              />
            </AgilityNoRecord>
          )}

          {this.state.searchClick &&
            i18n.exists(
              `comparePlan.genericMessage.${state.toLowerCase()}`
            ) && (
              <Container>
                <AgilityInfo severity="info" color="info">
                  {i18n.t(`comparePlan.genericMessage.${state.toLowerCase()}`)}
                </AgilityInfo>
              </Container>
            )}
          {this.state.searchClick && (
            <section>
              {this.state.findingByPostcode && (
                <React.Fragment>
                  <Container
                    css={css`
                      text-align: center;
                    `}
                  >
                    <AgilityTypography
                      variant="h2"
                      component="h2"
                      className={classes.postcodeHeadingOuter}
                    >
                      {i18n.t('offering.topinfo.postcode.heading')}
                      <span className={classes.postcodeHeading}>
                        &nbsp;
                        {this.props.planFilterData.enteredSearchLocation ||
                          this.state.postcode}
                      </span>
                    </AgilityTypography>
                    {i18n.exists('offering.topinfo.postcode.subheading') && (
                      <AgilityTypography
                        variant="caption"
                        component="p"
                        className={classes.grey}
                        dangerouslySetInnerHTML={{
                          __html: sanitizer(
                            i18n.t('offering.topinfo.postcode.subheading')
                          ),
                        }}
                      ></AgilityTypography>
                    )}
                  </Container>
                </React.Fragment>
              )}
              {this.state.selectedProducts.map((data, index) => (
                <div id={data} key={data}>
                  <ProductPlans
                    iIndex={index}
                    numberOfSelectedProducts={
                      this.state.selectedProducts.length
                    }
                    data-test-id={data}
                    key={data}
                    searchTrigger={this.state.searchTrigger}
                    productType={data}
                    productDetails={this.getProductDetails(data)}
                    isEmbeddedNetwork={this.props.isEmbeddedNetwork}
                    preloadSite={this.state.preloadSite}
                    scrollToAfterLoadingCompleted={this.allowScrollToProduct(
                      data
                    )}
                    apiCode={this.props.agent?.agent?.id}
                    distributor={this.getDistributor(data)}
                    findingByPostcode={this.state.findingByPostcode}
                    searchOffersNoClick={this.searchOffersNoClick}
                  />
                </div>
              ))}

              {i18n.exists('compareplan.promo.terms') && (
                <Container>
                  <AgilityTypography
                    component="p"
                    containerClass="mt-2 mb-2"
                    variant="subtitle1"
                    id="promo-terms"
                    dangerouslySetInnerHTML={{
                      __html: sanitizer(i18n.t('compareplan.promo.terms')),
                    }}
                  ></AgilityTypography>
                </Container>
              )}
              {this.state.distributors.length > 0 && (
                <Fragment>
                  {statePromoTermsKey && i18n.exists(statePromoTermsKey) && (
                    <Container>
                      <AgilityTypography
                        component="h6"
                        containerClass="mt-2 mb-2"
                        variant="h6"
                        id="further-terms-heading"
                      >
                        {i18n.t('compareplan.promo.terms.heading')}
                      </AgilityTypography>
                      <AgilityTypography
                        component="p"
                        containerClass="mb-4"
                        variant="subtitle2"
                        id="further-terms"
                        dangerouslySetInnerHTML={{
                          __html: sanitizer(i18n.t(statePromoTermsKey)),
                        }}
                      ></AgilityTypography>
                    </Container>
                  )}
                </Fragment>
              )}
              {this.state.selectedProducts.map((data, index) =>
                this.props.offerList?.[data]?.map(
                  (offer, index2) =>
                    offer.websiteTerms && (
                      <Container key={offer.id}>
                        <AgilityTypography
                          component="h6"
                          containerClass="mt-2 mb-1"
                          variant="h6"
                          id={`further-terms-${offer.name}`}
                        >
                          {offer.name}
                        </AgilityTypography>
                        <AgilityTypography
                          component="p"
                          containerClass="mb-2"
                          variant="subtitle2"
                          id="promo-terms"
                        >
                          {offer.websiteTerms}
                        </AgilityTypography>
                      </Container>
                    )
                )
              )}
            </section>
          )}
          {!isEmpty(this.props.siteIdentifierList) && (
            <AppBar className="selected-plan-button-wrapper" color="secondary">
              <Container>
                <ul
                  css={classes.selectedPlanButtonWrapperUl}
                  className="selected-plan-button-wrapper__inner"
                >
                  <li className="label">
                    <Hidden smDown>
                      {i18n.t('compareplan.scrollto.text')}
                    </Hidden>
                    <div
                      className={`product-scroll ${
                        this.state.selectedProducts.length === 1
                          ? 'single-data'
                          : ''
                      }`}
                    >
                      <Hidden mdUp>
                        <AgilityTypography
                          css={{
                            textAlign: 'left',
                          }}
                        >
                          {i18n.t('compareplan.scrollto.text')}
                        </AgilityTypography>
                      </Hidden>
                      <ul>
                        {this.state.selectedProducts.length !== 0 &&
                          this.state.selectedProducts.map((data, index) => (
                            <li
                              data-test-id={`link-${data}`}
                              className={`links${
                                (data === 'POWER' && powerPlanSelected) ||
                                (data === 'GAS' && gasPlanSelected)
                                  ? ' selected'
                                  : ''
                              }`}
                              data-key={data}
                              key={data}
                            >
                              <a href={`#${data}`}>
                                <FontAwesomeIcon
                                  icon={
                                    (data === 'POWER' && powerPlanSelected) ||
                                    (data === 'GAS' && gasPlanSelected)
                                      ? ['fas', 'check-circle']
                                      : ['fas', 'circle']
                                  }
                                  className="icon"
                                />
                                <div className="service-text">
                                  {
                                    getProductTypeByProductCode(
                                      this.props.productList,
                                      data
                                    ).display
                                  }
                                  <Hidden smDown>
                                    <br />
                                    {i18n.t('compareplan.services.text')}
                                  </Hidden>
                                </div>
                              </a>
                            </li>
                          ))}
                      </ul>
                    </div>
                  </li>
                  <li className="next-button" css={classes.nextButtonSpinner}>
                    <Hidden mdUp>
                      <AgilityButton
                        buttonClasses="brand-button"
                        size="large"
                        disabled={isEmpty(this.props.selectedPlans)}
                        data-test-id="plan-proceed-0"
                        // variant="outlined"
                        // color="secondary"
                        // label={i18n.t('button.proceed.label')}
                        variant={
                          isEmpty(this.props.selectedPlans)
                            ? 'outlined'
                            : 'contained'
                        }
                        color={
                          isEmpty(this.props.selectedPlans)
                            ? 'secondary'
                            : 'primary'
                        }
                        // label={
                        //   isEmpty(this.props.selectedPlans)
                        //     ? i18n.t('button.proceed-with-unselected.label')
                        //     : i18n.t('button.proceed-with-selected.label')
                        // }
                        label={i18n.t('button.proceed.label')}
                        onClick={() => this.onProceedClick()}
                      />
                    </Hidden>
                    <Hidden smDown>
                      <AgilityButton
                        disabled={isEmpty(this.props.selectedPlans)}
                        data-test-id="plan-proceed-1"
                        buttonClasses="brand-button"
                        size="large"
                        // variant="outlined"
                        // color="secondary"
                        // label={i18n.t('button.proceed-with-selected.label')}
                        variant={
                          isEmpty(this.props.selectedPlans)
                            ? 'outlined'
                            : 'contained'
                        }
                        color={
                          isEmpty(this.props.selectedPlans)
                            ? 'secondary'
                            : 'primary'
                        }
                        label={
                          isEmpty(this.props.selectedPlans)
                            ? i18n.t('button.proceed-with-unselected.label')
                            : i18n.t('button.proceed-with-selected.label')
                        }
                        onClick={() => this.onProceedClick()}
                        loading={this.state.proceedLoading}
                      />
                    </Hidden>
                  </li>
                </ul>
              </Container>
            </AppBar>
          )}
        </section>
        <AgilityInfoDialog
          maxWidth="md"
          // fullWidth={true}
          textAlign="center"
          titleAlign="center"
          className="info-dialog"
          data-test-id="postcode-dialog-id"
          open={this.state.showPostcodeDialog}
          title={i18n.t(
            'postcode.serviceable.dialog.heading',
            'We service your area!'
          )}
          okText={i18n.t(
            'postcode.serviceable.dialog.cta',
            "Let's find a plan!"
          )}
          closeClick={this.onCloseClickClick}
          okClick={this.onCloseClickClick}
          closeOnOutsideClick={true}
        >
          {i18n.t('postcode.serviceable.dialog.description')}
        </AgilityInfoDialog>
        <AgilityInfoDialog
          maxWidth="md"
          textAlign="center"
          titleAlign="center"
          className="info-dialog"
          data-test-id="unserviceable-dialog-id"
          open={this.state.showUnservicableDialog}
          title={i18n.t(
            'postcode.unserviceable.dialog.heading',
            'We service your area!'
          )}
          okText={i18n.t(
            'postcode.unserviceable.dialog.cta',
            "Let's find a plan!"
          )}
          closeClick={this.getApexDomainUrl}
          okClick={this.getApexDomainUrl}
          closeOnOutsideClick={false}
        >
          {i18n.t('postcode.unserviceable.dialog.description')}
        </AgilityInfoDialog>
        <div id="landmark_afterComparePlan"></div>
      </React.Fragment>
    );
  }
}
ComparePlan.propTypes = {
  planFilterData: object,
  selectedPlans: object,
  siteIdentifierList: object,
  productList: array,
  clearLowerSectionComparePlan: func,
  postSignupDetails: func,
  onLocationSelected: func,
  selectedSiteIdentifier: object,
  setPlanSelectionAction: func,
  setIdentifierSelectionAction: func,
  signupAPIResponse: object,
  putSignupDetails: func,
  getSignupDetails: func,
  allPlans: object,
  priceFilter: object,
  progressData: object,
  isEmbeddedNetwork: bool,
  getOfferAvailability: func,
  fetchDistributors: func,
  offerList: array,
};
ComparePlan.defaultProps = {
  planFilterData: {},
  selectedPlans: {},
  siteIdentifierList: {},
  productList: [],
  selectedSiteIdentifier: {},
  signupAPIResponse: {},
  allPlans: {},
  priceFilter: {},
  progressData: {},
  isEmbeddedNetwork: false,
};

export { ComparePlan };

export default withSnackbar(withStyles(styles)(ComparePlan));
