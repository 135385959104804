/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import React, { useState, useEffect } from 'react';
import {
  AgilityTypography,
  AgilityCard,
  AgilityGrid,
  AgilityButton,
  AgilityTextField,
} from 'Src/AgilityComponents';
import Container from '@material-ui/core/Container';
import i18n from 'i18next';
import { func } from 'prop-types';
import cookie from 'js-cookie';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { convertToDateOnly, isEmpty } from 'App/utils/helper';
import { withSnackbar } from 'notistack';
import { SIGNUP_BY_SALE } from 'App/utils/constants';
import { logSaleAction } from 'App/utils/geolocation';
import { useTheme } from '@material-ui/core/styles';

const ResumeSignup = props => {
  const theme = useTheme();
  const [loading, setLoading] = useState(false);

  const [fields, setFields] = useState({
    dateOfBirth: '',
    postcode: '',
  });
  const [errors, setErrors] = useState({
    dateOfBirth: '',
    postcode: '',
  });
  const [touched, setTouched] = useState({
    dateOfBirth: false,
    postcode: false,
  });

  const [account, setAccount] = useState({
    id: '',
    hash: '',
    name: '',
  });

  useEffect(() => {
    localStorage.clear();
    if (props.location && props.location.search) {
      const params = new URLSearchParams(props.location.search);
      setAccount({
        id: params.get('account_id'),
        name: params.get('name'),
      });
      logSaleAction(
        {
          accountId: params.get('account_id'),
        },
        'OPEN_LINK',
        true
      );
    } else {
      setLoading(false);
      setErrors({ dateOfBirth: true });

      return;
    }
  }, [props.location]);

  const maxDob = new Date(
    new Date().getFullYear() - 18,
    new Date().getMonth(),
    new Date().getDate()
  );

  const submitForm = e => {
    e.preventDefault();
    for (const key in touched) {
      touched[key] = true;
    }
    setTouched(touched);
    const isFormValid = validateForm();
    if (isFormValid) {
      setLoading(true);
      let data = { ...fields, ...account };

      props.validateSignupResumption(data).catch(error => {
        setLoading(false);
        if (error?.status === 400) {
          props.enqueueSnackbar(i18n.t('resumesignup.form.previouscompleted'), {
            variant: 'info',
          });
        } else {
          const params = new URLSearchParams(props.location.search);
          logSaleAction(
            {
              accountId: params.get('account_id'),
              context: fields['dateOfBirth'].split('-').reverse().join('/'),
            },
            'INVALID_UNLOCK',
            true
          );
          props.enqueueSnackbar(i18n.t('resumesignup.form.invalid'), {
            variant: 'error',
          });
        }
      });
    }
  };

  useEffect(() => {
    if (props.account.hash !== '') {
      setLoading(true);
      props
        .getSignupDetails(
          props.account.hash,
          props.account.id,
          fields['dateOfBirth']
        )
        .then(response => {
          if (props.account.expiredContract) {
            props.history.push('/expired-contract');
          } else {
            // @TODO fix this with propper D2D implementation
            const { agentEnteredCode } = cookie.get();

            if (agentEnteredCode !== undefined) {
              props.history.push('/plans');
            } else {
              localStorage.setItem(SIGNUP_BY_SALE, props.account.hash);
              props.history.push('/user-signup/selected-plan');
            }
          }
        })
        .catch(error => {
          setLoading(false);
          setErrors({ dateOfBirth: true });
        });
    }
    // eslint-disable-next-line
  }, [props.account.hash]);

  const handleChange = (val, name) => {
    fields[name] = val;
    touched[name] = true;
    setFields(fields);
    setTouched(touched);
    validateForm();
  };

  // this method validate all the form fields
  const validateForm = () => {
    const errorMessages = {};
    let formIsValid = true;

    // if agentId field is touched than check for error
    if (touched['dateOfBirth']) {
      if (!fields['dateOfBirth']) {
        formIsValid = false;
        errorMessages['dateOfBirth'] = i18n.t(
          'yourDetail.errorMessage.requiredDob'
        );
      } else if (typeof fields['dateOfBirth'] === 'undefined') {
        formIsValid = false;
        errorMessages['dateOfBirth'] = i18n.t(
          'yourDetail.errorMessage.requiredDob'
        );
      }
    }

    if (touched['postcode'] && isEmpty(fields['postcode'])) {
      formIsValid = false;
      errorMessages['postcode'] = i18n.t(
        'resumesignup.errorMessage.postcodeRequired'
      );
    }

    setErrors(errorMessages);
    return formIsValid;
  };

  const hasError = React.useCallback(
    field => errors && errors.hasOwnProperty(field) && errors[field] !== '',
    [errors]
  );

  return (
    <React.Fragment>
      <div
        css={css`
          display: flex;
          flex-direction: column;
          flex-grow: 1;
          background-color: ${theme.palette.primary.main};
          background-image: url(${theme.images.agentLoginMobile});
          background-repeat: no-repeat;
          background-size: cover;
          background-position: center;
          justify-content: center;
          @media (min-width: 600px) {
            background-image: url(${theme.images.agentLoginDesktop});
          }
        `}
      >
        <Container maxWidth={'md'}>
          <AgilityCard
            className={
              'agent-login ' +
              (process.env.REACT_APP_BRAND_CODE === 'FIRST' ? 'mt-4' : 'mt-2')
            }
          >
            <Container>
              <AgilityGrid
                container
                justifyContent="center"
                alignItems="center"
              >
                <AgilityGrid item lg={12} md={12} sm={12} xs={12}>
                  <AgilityTypography
                    variant="h3"
                    align="center"
                    className="mb-2"
                  >
                    {i18n.t('resumesignup.header', {
                      name: account['name'] || 'there',
                    })}
                  </AgilityTypography>
                </AgilityGrid>

                <AgilityGrid item lg={5} md={6} sm={8} xs={12}>
                  <form
                    onSubmit={e => submitForm(e)}
                    autoComplete="off"
                    noValidate
                    data-test-id="loginForm"
                  >
                    <div className="text-field-wrapper">
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          fullWidth
                          variant="inline"
                          inputVariant="outlined"
                          format={window.dateFormat}
                          id="dateOfBirth"
                          label={i18n.t('yourDetail.placeholder.dob', {
                            dateFormat: window.dateFormat.toUpperCase(),
                          })}
                          value={fields['dateOfBirth'] || null}
                          disabled={loading}
                          data-test-id="dateOfBirth"
                          onChange={val =>
                            handleChange(convertToDateOnly(val), 'dateOfBirth')
                          }
                          KeyboardButtonProps={{
                            'aria-label': 'change date',
                          }}
                          autoOk
                          maxDate={maxDob}
                          maxDateMessage={i18n.t(
                            'yourDetail.errorMessage.maxDateDob'
                          )}
                          // onError={onDobError}
                          required
                          helperText={
                            hasError('dateOfBirth') ? errors.dateOfBirth : ''
                          }
                          error={hasError('dateOfBirth')}
                        />
                      </MuiPickersUtilsProvider>
                    </div>
                    <div className="text-field-wrapper">
                      <AgilityTextField
                        id="postcode"
                        data-test-id="postcode"
                        type="number"
                        maxLength={4}
                        fullWidth
                        placeholder={i18n.t(
                          'resumesignup.placeholder.postcode'
                        )}
                        value={fields.postcode || ''}
                        disabled={loading}
                        onChange={val => handleChange(val, 'postcode')}
                        variant="outlined"
                        required
                        helperText={hasError('postcode') ? errors.postcode : ''}
                        error={hasError('postcode')}
                      />
                    </div>

                    <AgilityButton
                      variant="contained"
                      color="primary"
                      type="primary"
                      className="mb-2"
                      fullWidth
                      label={'Submit'}
                      data-test-id="submitButton"
                      htmltype={'submit'}
                      loading={loading}
                    />
                  </form>
                </AgilityGrid>
              </AgilityGrid>
            </Container>
          </AgilityCard>
        </Container>
      </div>
    </React.Fragment>
  );
};

ResumeSignup.propTypes = {
  validateSignupURL: func,
};
ResumeSignup.defaultProps = {};

export default withSnackbar(ResumeSignup);
