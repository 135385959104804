const checkEnv = val => {
  return (val || '').toLowerCase() === 'true';
};

const nodeEnv = `${process.env.NODE_ENV}`;

module.exports = {
  // Retrieves and set data From .env
  // Input control settings
  disableSecondaryContact: checkEnv(
    process.env.REACT_APP_DISABLE_SECOND_CONTACT
  ),
  isEmbeddedNetwork: checkEnv(process.env.REACT_APP_IS_EMBEDDED_NETWORK),
  showLifeSupport: checkEnv(process.env.REACT_APP_SHOW_LIFE_SUPPORT),
  showAnyRisks: checkEnv(process.env.REACT_APP_SHOW_ANY_RISKS),
  showAnyObstacles: checkEnv(process.env.REACT_APP_SHOW_ANY_OBSTACLES),
  showMeterQuestion: checkEnv(
    process.env.REACT_APP_SHOW_METER_COORDINATOR_QUESTION
  ),
  showSiteInfo: checkEnv(process.env.REACT_APP_SHOW_SITE_INFO),
  enableOneOffPayment: checkEnv(process.env.REACT_APP_ENABLE_ONE_OFF_PAYMENT),
  enableCreditCardPayment: checkEnv(
    process.env.REACT_APP_ENABLE_CREDIT_CARD_DIRECT_DEBIT
  ),
  showHasConnection: checkEnv(process.env.REACT_APP_SHOW_HAS_CONNECTION),
  showBillingFrequency: checkEnv(process.env.REACT_APP_SHOW_BILL_FREQUENCY),
  showEstimatedBillingFrequency: checkEnv(
    process.env.REACT_APP_SHOW_ESTIMATED_BILL_FREQUENCY
  ),
  showPreferContact: checkEnv(process.env.REACT_APP_SHOW_PREFER_CONTACT),
  showHearAboutUs: checkEnv(process.env.REACT_APP_SHOW_HEAR_ABOUT_US),
  showPlanImage: checkEnv(process.env.REACT_APP_SHOW_DEFAULT_PLAN_IMAGE),
  showPaymentRequest: checkEnv(process.env.REACT_APP_SHOW_PAYMENT_REQUEST),
  showDirectDebitStep: checkEnv(process.env.REACT_APP_SHOW_PAYMENT_REQUEST),
  showBillingStep: checkEnv(process.env.REACT_APP_SHOW_BILLING_OPTIONS),
  showCreditCheckTerms: checkEnv(process.env.REACT_APP_HAS_CREDIT_CHECK_TERMS),
  hasGasSupply: checkEnv(process.env.REACT_APP_GAS_SUPPLY),
  sellsResidentialProducts: checkEnv(process.env.REACT_APP_RETAILER_SELLS_RESI),
  sellsBusinessProducts: checkEnv(process.env.REACT_APP_RETAILER_SELLS_SME),
  disableReceiveBillByPost: checkEnv(
    process.env.REACT_APP_DISABLE_RECEIVE_BILL_BY_POST
  ),
  showSameDeliveryMethod: checkEnv(
    process.env.REACT_APP_SHOW_SAME_DELIVERY_METHOD
  ),
  showSendSmsLink: checkEnv(process.env.REACT_APP_SHOW_SEND_SMS_LINK),
  showSendSmsLinkOnlyToD2DAgents: checkEnv(
    process.env.REACT_APP_SHOW_SEND_SMS_LINK
  ),
  allowsSameDayFuelInjections: checkEnv(
    process.env.REACT_APP_ALLOW_SAME_DAY_CONNECTIONS
  ),
  isDoorToDoor: checkEnv(process.env.REACT_APP_ENABLE_DOOR_TO_DOOR),
  agentLoginHasPassword: checkEnv(
    process.env.REACT_APP_AGENT_AUTH_WITH_PASSWORD
  ),
  marketRetailTermsIsALink: checkEnv(
    process.env.REACT_APP_MARKET_RETAIL_TERM_AND_CONDITION_IS_A_LINK
  ),
  directDebitIsRequired: checkEnv(
    process.env.REACT_APP_DIRECT_DEBIT_IS_REQUIRED
  ),
  enableExperianMobileValidation: checkEnv(
    process.env.REACT_APP_ENABLE_EXPERIAN_MOBILE_VALIDATION
  ),
  enableExperianEmailValidation: checkEnv(
    process.env.REACT_APP_ENABLE_EXPERIAN_EMAIL_VALIDATION
  ),
  showOfferTilesMonthlyEstimate: checkEnv(
    process.env.REACT_APP_SHOW_OFFER_TILES_MONTHLY_ESTIMATE
  ),
  showMonthlyEstimateForBasicMeters: checkEnv(
    process.env.REACT_APP_SHOW_MONTHLY_ESTIMATE_FOR_BASIC_METERS
  ),
  showConnectionTermsAndConditions: checkEnv(
    process.env.REACT_APP_SHOW_CONNECTION_TERMS_AND_CONDITIONS
  ),
  linkToMainWebsite: checkEnv(process.env.REACT_APP_LOGO_LINKS_TO_HOMEPAGE),
  showSolarType: checkEnv(process.env.REACT_APP_SHOW_SOLAR_TYPE),
  allowAgentEnterCustomerData: checkEnv(
    process.env.REACT_APP_ALLOW_AGENT_ENTER_CUSTOMER_DATA
  ),
  disableRentOwnQuestion: checkEnv(
    process.env.REACT_APP_DISABLE_RENT_OWN_QUESTION
  ),
  hasEICTermsCheckbox: checkEnv(process.env.REACT_APP_HAS_EIC_CHECKBOX),
  hideSiteIdentifierInfo: checkEnv(
    process.env.REACT_APP_HIDE_SITE_IDENTIFIER_INFO
  ),
  hasMobileAuthentication: checkEnv(
    process.env.REACT_APP_HAS_MOBILE_AUTHENTICATION
  ),
  hasCreditReportDialog: checkEnv(
    process.env.REACT_APP_HAS_CREDIT_REPORT_DIALOG
  ),
  showRefPriceMonthlyComparisonStriked: checkEnv(
    process.env.REACT_APP_SHOW_REF_PRICE_MONTHLY_COMPARISON_STRIKED
  ),
  businessIdentificationOptional: checkEnv(
    process.env.REACT_APP_BUSINESS_IDENTIFICATION_OPTIONAL
  ),
  disableSignupsForLifeSupportCustomers: checkEnv(
    process.env.REACT_APP_DISABLE_SIGNUPS_FOR_LIFE_SUPPORT_CUSTOMERS
  ),
  disableSignupsForMedicalCoolingCustomers: checkEnv(
    process.env.REACT_APP_DISABLE_SIGNUPS_FOR_MEDICAL_COOLING_CUSTOMERS
  ),
  showDebugInfoBar:
    checkEnv(process.env.REACT_APP_SHOW_DEBUG_INFO) && nodeEnv !== 'production',
  showPromoCodeInput: checkEnv(process.env.REACT_APP_SHOW_PROMOCODES),
  isXSellGas: checkEnv(process.env.REACT_APP_IS_XSELL_GAS),
  prefillPostcodeByIpLocation: checkEnv(
    process.env.REACT_APP_DO_NOT_PREFILL_POSTCODE_BY_IP_LOCATION
  ),
  referAFriend: checkEnv(process.env.REACT_APP_REFER_A_FRIEND),
  referAFriendSocialButtons: checkEnv(
    process.env.REACT_APP_REFER_A_FRIEND_SOCIAL_BUTTONS
  ),
  factsheetsPage: checkEnv(process.env.REACT_APP_FACTSHEETS_PAGE),
  sortOffersByPrice: checkEnv(process.env.REACT_APP_SORT_OFFERS_BY_PRICE),
  selectFirstGreenpowerOption: checkEnv(
    process.env.REACT_APP_SELECT_FIRST_GREENPOWER_OPTION
  ),
  /**
   * These three Business Type configs are unused. I believe they used to be used in validating if an ACN was required
   * for the business type, in CompanyDetail.js. The UCONX equivalent (organisation_type.requires_acn) is also no longer
   * used, but still exists just in case. So we'll do the same thing here.
   */
  incorporationBusinessTypeIsRequired: checkEnv(
    process.env.REACT_APP_INCORPORATION_BUSINESS_TYPE_IS_REQUIRED
  ),
  trustBusinessTypeIsRequired: checkEnv(
    process.env.REACT_APP_TRUST_BUSINESS_TYPE_IS_REQUIRED
  ),
  limitedCompanyBusinessTypeIsRequired: checkEnv(
    process.env.REACT_APP_LIMITED_COMPANY_BUSINESS_TYPE_IS_REQUIRED
  ),
  isPacificHydro:
    process.env.REACT_APP_BRAND_CODE === 'TANGO' ||
    process.env.REACT_APP_BRAND_CODE === 'PACIFICBLUE',
  hasInterestOnInternetCheckbox: checkEnv(
    process.env.REACT_APP_HAS_INTEREST_ON_INTERNET
  ),
  unselectHasConcessionCard: checkEnv(
    process.env.REACT_APP_UNSELECT_HAS_CONCESSION_CARD
  ),
};
